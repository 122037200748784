import React from 'react';
import LoginLogo from '../../components/login/LoginLogo';

const TermsPage = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    backgroundColor: '#ffffff',
    color: '#555555',
  };

  const headingStyle = {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  };

  const sectionHeadingStyle = {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '12px',
  };

  const listItemStyle = {
    marginBottom: '8px',
  };

  const linkStyle = {
    fontSize: '14px',
    fontFamily: 'Outfit-Bold',
    color: '#555555',
    textDecoration: 'none',
    cursor: 'pointer',
  };

  const NavigationToRegiser = () => {
    // Define your navigation logic here
    console.log('Navigate to Terms & Conditions page');
  };

  return (
    <div style={containerStyle}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <LoginLogo />
        <h1 style={headingStyle}>Terms and Conditions</h1>
      </div>
      <div style={{ paddingLeft: '100px', paddingRight: '100px' }}>
        <p className="mb-4">Welcome to Luckydraw Maldives. By accessing and using this website, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully.</p>

        <h2 style={sectionHeadingStyle}>1. General Terms of Use for Merchants</h2>

        <h3 style={sectionHeadingStyle}>Account Creation:</h3>
        <p className="mb-4">Merchants can register and create a user account for free on our website. This account allows merchants to list lucky draws and promotional posts.</p>

        <h3 style={sectionHeadingStyle}>Eligibility:</h3>
        <p className="mb-4">Only merchant users who are residents of and registered in the Maldives are eligible to create and list lucky draws and promotions on this site.</p>

        <h3 style={sectionHeadingStyle}>Postings:</h3>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>Merchants may list as many posts as needed, provided they comply with Maldivian law.</li>
          <li style={listItemStyle}>Merchants must ensure they have the legal right to possess and give away the items listed and that these items are available at the time of posting.</li>
          <li style={listItemStyle}>Posts are subject to charges based on the type of post created.</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Prohibited Items:</h3>
        <p className="mb-4">The following items are strictly prohibited from being listed:</p>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>Intoxicants including alcoholic beverages and drugs</li>
          <li style={listItemStyle}>Tobacco products, including cigarettes, hookah, and accessories</li>
          <li style={listItemStyle}>Electronic cigarettes and accessories</li>
          <li style={listItemStyle}>Dating and adult services</li>
          <li style={listItemStyle}>Pornographic material, nudity, and obscene or adult-oriented products</li>
          <li style={listItemStyle}>Listings with offensive text or images</li>
          <li style={listItemStyle}>Prescription drugs and medical devices</li>
          <li style={listItemStyle}>Supplements with misleading treatment claims</li>
          <li style={listItemStyle}>Referral advertisements</li>
          <li style={listItemStyle}>"Get rich quick" schemes, pyramid schemes, and other similar money-making schemes</li>
          <li style={listItemStyle}>Stolen, counterfeit, and pirated goods, such as copied movies and software</li>
          <li style={listItemStyle}>Non-original products falsely labeled as original</li>
          <li style={listItemStyle}>Advertisements for other classifieds and online shopping sites, including any addresses of such sites in the listing title, description, or images</li>
          <li style={listItemStyle}>Currency</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Spam and Duplicate Listings:</h3>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>Repeatedly posting the same lucky draw or promotion can result in the post being marked as spam and not approved.</li>
          <li style={listItemStyle}>We reserve the right to not approve listings from users who engage in spammy behavior, to disable all approved listings from such users, or to suspend their accounts.</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Multiple Accounts:</h3>
        <p className="mb-4">Submitting the same listings under multiple accounts is prohibited.</p>

        <h3 style={sectionHeadingStyle}>Brand Integrity:</h3>
        <p className="mb-4">Merchants are not allowed to post lucky draws or promotions under an existing brand or trade name unless they own the brand or trade name.</p>

        <h3 style={sectionHeadingStyle}>Merchant Responsibility:</h3>
        <p className="mb-4">Merchants are responsible for contacting winning participants and ensuring all terms of the giveaways, including delivery, are agreed upon. We are not liable for any loss or damage arising from giveaways and gifts related to posts on this site.</p>

        <h3 style={sectionHeadingStyle}>Content Approval:</h3>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>We reserve the right to reject any posts we deem inappropriate for publication on the site.</li>
          <li style={listItemStyle}>We reserve the right to reject listings we consider unrealistic.</li>
          <li style={listItemStyle}>Listings must not contain misleading pictures or descriptions. We may reject posts with images we consider inappropriate or indecent, including removing such posts completely from the site.</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Visit and Win Posts:</h3>
        <p className="mb-4">Merchants creating "Visit and Win" posts must conduct their own draw according to their conditions at the specified store or outlet location. The website will only act as an advertisement platform and guide for participants; we are not responsible for the selection of winners or any process involved in the "Visit and Win" lucky draw except for displaying the post on the website.</p>

        <h3 style={sectionHeadingStyle}>Compliance with Law:</h3>
        <p className="mb-4">We take the sale of stolen goods seriously and will fully support investigating authorities. This includes providing information about the seller, such as email, phone number and any other information available in this website.</p>

        <h2 style={sectionHeadingStyle}>2. General Terms of Use for Participants</h2>

        <h3 style={sectionHeadingStyle}>Account Creation:</h3>
        <p className="mb-4">Participants can create a user account for free on our website.</p>

        <h3 style={sectionHeadingStyle}>Interaction:</h3>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>Participants can interact with all posts placed by merchants for free.</li>
          <li style={listItemStyle}>Posts created under the “like” category can be liked only once by each participant.</li>
          <li style={listItemStyle}>Posts created under the “share” category can be shared multiple times by participants.</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Visit and Win Posts:</h3>
        <p className="mb-4">Participants must visit the specified geographical location of the store or outlet to participate in the draw according to the merchant's terms.</p>

        <h3 style={sectionHeadingStyle}>Participant Responsibilities:</h3>
        <p className="mb-4">Winning participants should contact the merchant if the merchant does not establish contact and ensure all giveaway terms, including delivery, are agreed upon. We are not liable for any loss or damage arising from giveaways and gifts related to posts on this site.</p>

        <h2 style={sectionHeadingStyle}>3. General Terms of Use for All Users</h2>

        <h3 style={sectionHeadingStyle}>Service Provision:</h3>
        <p className="mb-4">We provide a platform for listing lucky draws and promotional ads.</p>

        <h3 style={sectionHeadingStyle}>Username Requirements:</h3>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>Usernames must not contain vulgar, abusive, or offensive words.</li>
          <li style={listItemStyle}>Usernames cannot contain obscenity, profanity, another party's brand/trademark, or the word "Luckydraw Maldives".</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Account Management:</h3>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>Accounts, once registered, cannot be deleted but can be deactivated upon customer request.</li>
          <li style={listItemStyle}>Only one account per email or mobile number is allowed.</li>
          <li style={listItemStyle}>If your registered mobile number changes, the account must be deactivated, and a new account created, as mobile numbers cannot be changed once registered.</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Account Use:</h3>
        <p className="mb-4">Accounts should only be registered for personal use. Users are responsible for all activities carried out by their account. The account created on Luckydraw Maldives can be used to log into other sites managed by Shawfan Corporation Pvt Ltd.</p>

        <h3 style={sectionHeadingStyle}>Liability:</h3>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', marginBottom: '20px' }}>
          <li style={listItemStyle}>We are not liable for any indirect or consequential loss of any nature arising out of or in connection with your use of the website, including interactions listed on this site.</li>
          <li style={listItemStyle}>You agree to indemnify us against all claims, liabilities, damages, costs, and expenses, including legal fees, arising out of any breach of these Terms of Service by you.</li>
          <li style={listItemStyle}>We may terminate your use of the website immediately if we consider that you have breached these Terms of Service.</li>
        </ul>

        <h3 style={sectionHeadingStyle}>Account Suspension:</h3>
        <p className="mb-4">Failure to comply with our Terms of Use will result in your account being suspended.</p>

        <h2 style={sectionHeadingStyle}>4. Payment Terms</h2>

        <h3 style={sectionHeadingStyle}>Accepted Payments:</h3>
        <p className="mb-4">We accept American Express, Visa, Mastercard</p>

        <h3 style={sectionHeadingStyle}>Currency:</h3>
        <p className="mb-4">Payments are accepted in both Maldivian Rufiyaa and US Dollars.</p>

        <h3 style={sectionHeadingStyle}>Data Security:</h3>
        <p className="mb-4">Our website ensures that cardholder information is not stored. All transactions are securely transmitted to trusted third-party payment processors. This practice enhances security, reduces the risk of data breaches, and ensures compliance with industry standards, providing peace of mind and safeguarding your financial data.</p>

        <h3 style={sectionHeadingStyle}>Record Keeping:</h3>
        <p className="mb-4">Cardholders are advised to retain a copy of transaction records and terms and conditions of this website.</p>

        <h2 style={sectionHeadingStyle}>5. Refund Policy</h2>
        <p className="mb-4">No Refunds: For services provided by Luckydraw Maldives, such as posting listings in the premium category, there are no returns or refunds unless the service is not received by the merchant user.</p>

        <h2 style={sectionHeadingStyle}>6. Updates</h2>
        <p className="mb-4">These Terms and Conditions are updated constantly and may change without notice. It is your responsibility to review these Terms periodically.</p>

        <h2 style={sectionHeadingStyle}>7. Contact Information</h2>
        <p className="mb-4">Shawfan Corporation Pvt Ltd<br />H. Mariyammaage, K. Male’, Republic of Maldives<br />Tel: +960 7900766</p>

        <p className="mb-4">By using Luckydraw Maldives, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
      </div>
    </div>
  );
};

export default TermsPage;
