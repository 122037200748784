import {
  Box,
  Container,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomModal from "../common/CustomModal";
import CustomTitle from "../common/CustomTitle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import CustomImageUploader from "../common/CustomImageUploder";
import CustomSelect from "../common/CustomSelect";
import CustomTextArea from "../common/CustomTextArea";
import CustomSwitch from "../common/CustomSwitch";
import CustomButton from "../common/CustomButton";
import CustomBackArrow from "../common/CustomBackArrow";
import {
  redirect,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CustomDateTimePicker from "../common/CustomDateTimePicker";
import moment from "moment";
import { useSnackbar } from "../../hooks/SnackBarHook";
import {
  CreatePost,
  UpdatePost,
  getPayment,
  getPostShow,
} from "../../api/post";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IMG_URL } from "../../config";
import Reactotron from "reactotron-react-js";

export const PostEditView = () => {
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const location = useLocation();
  const { state } = location;
  const { postId } = useParams();
  const [loading, setLoading] = useState(false);

  const [time, setTime] = useState(null);
  const [postPreview, setPostPreview] = useState(null);
  const [imageFilePost, setImageFilePost] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [imageFileCover, setImageFileCover] = useState(null);
  const [postSelect, setPostSelect] = useState(null);
  const [buttonSelect, setButtonSelect] = useState(null);

  console.log(imageFilePost, "IMAGEFILE")

  const schema = yup.object().shape({
    deadline: yup.string().required("Required"),
    type: yup.string().required("Required"),
    button_type: yup.string().required("Required"),
    title: yup.string().required("Required"),
    description: yup.string().required("Required"),
    post_image: yup.mixed().required("Required"),
    price_image: yup.mixed().required("Required"),
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data } = useQuery({
    queryKey: ["payment"],
    queryFn: getPayment,
  });
  const { data: postShowData } = useQuery({
    queryKey: ["postShow"],
    queryFn: () => getPostShow(postId),
    enabled: !!postId,
  });

  useEffect(() => {
    if (postShowData?.data?.data && postId) {
      const key = postShowData.data.data;
      setValue("deadline", moment(key.deadline));
      setValue("type", key.type);
      setValue("button_type", key.button_type);
      setValue("description", key.description);
      setValue("title", key.title);
      setValue("amount", key.amount);
      setValue("winner", key.winner?.user?.first_name);
      setButtonSelect(key.button_type);
      setPostSelect(key.type);
      setValue("post_image", key.post_image);
      setValue("price_image", key.price_image);
      setPostPreview(IMG_URL + key.post_image);
      setCoverPreview(IMG_URL + key.price_image);
    }
  }, [postShowData?.data?.data, postId, setValue]);

  const imageSize = (fileUpload, width, height) => {
    return new Promise((resolve, reject) => {
      if (!fileUpload) {
        reject(new Error("No file uploaded"));
        return;
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(fileUpload);
  
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
  
        image.onload = () => {
          if (image.width === width && image.height === height) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
  
        image.onerror = () => {
          reject(new Error("Invalid image"));
        };
      };
  
      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
    });
  };
  
  const ImageUploaderPost = async (file) => {
    if (!file) {
      setPostPreview(null);
      setImageFilePost(null);
      setError("post_image", { message: "No file uploaded" });
      return;
    }
  
    try {
      const isValidSize = await imageSize(file, 800, 400);
  
      if (file.size <= 2000000 && isValidSize) {
        setImageFilePost(file);
        setPostPreview(null);
        setValue("post_image", file);
        clearErrors("post_image"); // Clear error if valid image
      } else {
        setPostPreview(null);
        setImageFilePost(null);
        setError("post_image", { message: "Invalid image size or dimensions" });
      }
    } catch (error) {
      setPostPreview(null);
      setImageFilePost(null);
      setError("post_image", { message: error.message });
    }
  };
  
  const ImageUploaderPrize = async (file) => {
    if (!file) {
      setCoverPreview(null);
      setImageFileCover(null);
      setError("price_image", { message: "No file uploaded" });
      return;
    }
  
    try {
      const isValidSize = await imageSize(file, 300, 300);
  
      if (file.size <= 2000000 && isValidSize) {
        setImageFileCover(file);
        setCoverPreview(null);
        setValue("price_image", file);
        clearErrors("price_image"); // Clear error if valid image
      } else {
        setCoverPreview(null);
        setImageFileCover(null);
        setError("price_image", { message: "Invalid image size or dimensions" });
      }
    } catch (error) {
      setCoverPreview(null);
      setImageFileCover(null);
      setError("price_image", { message: error.message });
    }
  };
  
  
  
  const { mutate, isLoading: settingLoading } = useMutation({
    mutationFn: postId ? UpdatePost : CreatePost,
    onSuccess: async (data) => {
      if (data?.data?.message === "success") {
        let details = data?.data?.data;

        const { amount, type, payment } = details;
        if (type === "premium" && parseInt(amount) > 0 && !postId) {
          //redirect(payment?.payment_details?.url)
          window.location.href = payment?.payment_details?.url;
          //setLoading(false)
        } else {
          reset();
          showSnackbar(
            postId ? "Updated succesfully!" : "Created succesfully!",
            "success"
          );
          navigate(-1);
          setLoading(false);
        }
      }
    },
    onError: (error) => {
      setLoading(false);
      showSnackbar(error.message, "error");
    },
  });

  const OnChangeDate = (value) => {
    setValue("deadline", value);
    setTime(value);
    setError("deadline", { message: "" });
  };

  const onChangeButton = (e) => {
    setValue("button_type", e.target.value);
    setButtonSelect(e.target.value);
  };

  const onChangePost = (e) => {
    const { value } = e.target;
    const diff = moment(time).diff(moment(), "days") + 1;
    const amount = value === "premium" 
      ? parseFloat(data?.data?.data?.withtax?.premium_post_price) * diff 
      : parseFloat(data?.data?.data?.withtax?.normal_post_price) * diff;
    setValue("amount", amount);
    setPostSelect(value);
    setValue("type", value);
  };

  const onSubmit = async (data) => {
    const result = await trigger(); // Trigger validation
  
    if (!result) {
      showSnackbar("Please fix the errors before submitting the form", "error");
      return;
    }
  
    submitForm(data);
  };

  const submitForm = async (data) => {
    // Check for validation errors in image fields
    // const hasErrors = Object.keys(errors).length > 0;
    // if (hasErrors) {
    //   showSnackbar("Please fix the errors before submitting the form", "error");
    //   return;
    // }
  
    setLoading(true);
    const formData = new FormData();
    if (imageFilePost) {
      formData.append("post_image", imageFilePost);
    }
    if (imageFileCover) {
      formData.append("price_image", imageFileCover);
    }
    if (postId) {
      formData.append("id", postId);
    }
    formData.append("deadline", moment(data?.deadline).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("type", data?.type);
    formData.append("title", data?.title);
    formData.append("amount", data?.amount);
    formData.append("description", data?.description);
    formData.append("button_type", data?.button_type);
    
    mutate(formData);
  };
  

  return (
    <Box px={2} py={2}>
      <CustomBackArrow label={`${state} Post`} />
      <Box px={5}>
        <Grid container spacing={4} my={2}>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <CustomDateTimePicker
              values={time}
              changeValue={(value) => OnChangeDate(value)}
              fieldName="deadline"
              control={control}
              error={errors.deadline}
              fieldLabel={"Dead Line"}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <CustomSelect
              readOnly={postId ? true : false}
              control={control}
              error={errors.type}
              fieldName="type"
              fieldLabel="Post Type"
              size="16px"
              value={postSelect}
              onChangeValue={(e) => onChangePost(e)}
            >
              <MenuItem value="" disabled>
                <em>Status</em>
              </MenuItem>
              {[
                { id: 1, name: "Premium", value: "premium" },
                { id: 2, name: "Normal", value: "normal" },
              ].map((res, i) => (
                <MenuItem value={res.value}>{res?.name}</MenuItem>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={1} sm={4} xs={12}>
            <CustomInput
              readonly={true}
              control={control}
              error={errors.amount}
              fieldName="amount"
              fieldLabel="Amount"
            />
          </Grid>
          <Grid item xl={2} lg={2} md={1} sm={4} xs={12}></Grid>
          <Grid item xl={2} lg={2} md={1} sm={4} xs={12}></Grid>
          <Grid item xl={2} lg={2} md={1} sm={4} xs={12}></Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <CustomTextArea
              readOnly={state === "View" ? true : false}
              control={control}
              error={errors.title}
              fieldName="title"
              multiline={true}
              height={90}
              row={10}
              fieldLabel="Post Title"
            />
          </Grid>
          <Grid item xl={10} lg={10} md={3} sm={4} xs={12}>
            <CustomTextArea
              readOnly={state === "View" ? true : false}
              control={control}
              error={errors.description}
              fieldName="description"
              multiline={true}
              height={90}
              row={10}
              fieldLabel="Post Description"
            />
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <CustomSelect
              readOnly={state === "View" ? true : false}
              control={control}
              error={errors.button_type}
              fieldName="button_type"
              fieldLabel="Post Button Type"
              size="16px"
              value={buttonSelect}
              onChangeValue={(e) => onChangeButton(e)}
            >
              <MenuItem value="" disabled>
                <em>Status</em>
              </MenuItem>
              {[
                { id: 1, name: "Like", value: "like" },
                { id: 2, name: "Share", value: "share" },
                { id: 3, name: "Visit", value: "visit" },
              ].map((res, i) => (
                <MenuItem value={res.value}>{res?.name}</MenuItem>
              ))}
            </CustomSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <CustomImageUploader
              ICON={""}
              hide={state === "View" ? true : false}
              viewImage={postPreview}
              error={errors.post_image}
              fieldName="post_image"
              placeholder={``}
              fieldLabel={"Post Image"}
              control={control}
              height={{ xl: 160, lg: 150, md: 150, sm: 150, xs: 140 }}
              max={5}
              onChangeValue={ImageUploaderPost}
              preview={imageFilePost}
              previewEditimage={""}
              type={"file"}
              background="#e7f5f7"
              myid="contained-button-file"
              width={"100%"}
            />
            <p
              style={{
                fontSize: 12,
                fontStyle: "italic",
                color: "darkgrey",
                fontWeight: "bold",
                marginTop: 8,
              }}
            >
              ( Recommended Size: 800*400 )
            </p>
          </Grid>
          <Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
            <CustomImageUploader
              ICON={""}
              hide={state === "View" ? true : false}
              viewImage={coverPreview}
              error={errors.price_image}
              fieldName="price_image"
              placeholder={``}
              fieldLabel={"Prize Image"}
              control={control}
              height={{ xl: 160, lg: 150, md: 150, sm: 150, xs: 140 }}
              max={5}
              onChangeValue={ImageUploaderPrize}
              preview={imageFileCover}
              previewEditimage={""}
              type={"file"}
              background="#e7f5f7"
              myid="contained-button-file2"
              width={"100%"}
            />
            <p
              style={{
                fontSize: 12,
                fontStyle: "italic",
                color: "darkgrey",
                fontWeight: "bold",
                marginTop: 8,
              }}
            >
              ( Recommended Size: 300*300 )
            </p>
          </Grid>
          <Grid item xl={2} lg={2} md={1} sm={4} xs={12}>
            <CustomInput
              readonly={true}
              control={control}
              error={errors.winner}
              fieldName="winner"
              fieldLabel="Selected Winner"
            />
          </Grid>
        </Grid>

        {state !== "View" && (
          <Box display={"flex"} justifyContent={"center"} py={5}>
            <CustomButton
              disable={loading || settingLoading}
              isIcon={false}
              label={
                loading ? "Loading.." : state === "Edit" ? "Update" : "Confirm"
              }
              onClick={handleSubmit(onSubmit)} 
              width={{ xl: "20%", lg: "20%", md: "25%", sm: "60%", xs: "100%" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
