// src/components/PaymentProcessing.js

import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { axiosInstance } from '../customAxios';


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled('div')(({ theme }) => ({
    margin: '0 auto',
    border: `4px solid red`,
    borderTop: `4px solid blue`,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: `${spin} 1s linear infinite`,
}));


const FullScreenBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly transparent background
    zIndex: 9999, // Ensure it is on top
});

const PaymentProcessing = () => {

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    console.log({ searchParams: searchParams.get("transactionId") })


    useEffect(() => {
        let transactionId = searchParams.get("transactionId")

        async function verifyPayment(transactionId) {
            let { data } = await axiosInstance.get(`merchant/checkPayment/${transactionId}`)

            if (data?.message === "completed") {
                //payment success
                navigate('/payment_success')
            }
            else {
                //payment failed
                navigate('/payment_failed')
            }

            console.log({ data })
        }

        if (transactionId) {
            verifyPayment(transactionId)
        }
    }, [])


    return (
        <FullScreenBox>
            <Box mt={5} textAlign="center">
                <Spinner />
                <Typography variant="h5" mt={2}>
                    Payment is processing.
                </Typography>
                <Typography variant="body1" mt={2}>
                    Please wait while we process your payment.
                </Typography>
            </Box>
        </FullScreenBox>
    );
};

export default PaymentProcessing;
