import React, { useCallback, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Grid, TextField, Button, Box, Avatar, Typography } from '@mui/material';
import { COLOURS } from '../../assets/COLORS';
import CustomButton from '../../components/common/CustomButton';
import LoginBackground from '../../components/login/LoginBackground';
import LoginLogo from '../../components/login/LoginLogo';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../hooks/SnackBarHook';
import { forgetPassword } from '../../api/login';
import { useMutation } from '@tanstack/react-query';
import OtpInput from 'react-otp-input';




const Otp = () => {

    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [otpValue, setOtpValue] = useState('');

    


    const { mutate, isLoading, error } = useMutation({
        mutationFn: forgetPassword,
        onSuccess: async (data) => {
            showSnackbar('Sent succesfully', 'success');
            navigate('/login')
        },
        onError: (error, variables, context) => {
            showSnackbar(error?.message, 'error');
        },
    })


    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));

    const SubmitForm = (data) => {
        mutate(data)
    }
    const NavigationGoBack = useCallback(() => {
        navigate('/login')
    }, [])

    return (
        <Grid container>
            {/* Left side with background image */}
            <LoginBackground />
            {/* Right side with input fields and button */}
            <Grid item xs={12} sm={isMdScreen ? 4 : 12} style={{ width: isMdScreen ? '50%' : '100%', height: '100vh' }}>
                <Grid
                    container
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    px={2}
                    style={{ height: '100%', textAlign: 'center', margin: 0, background: COLOURS.primary }}
                >

                    <Box sx={{ width: isMdScreen ? '70%' : '100%', gap: 3, display: 'flex', flexDirection: 'column' }}>
                        <Grid item xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LoginLogo />
                        </Grid>
                        <Grid item xl={12} xs={12}  >
                            <Typography sx={{ fontSize: 30, fontFamily: 'Outfit-Bold' }}>Enter OTP</Typography>
                        </Grid>
                        <Grid item xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                            <Box width={'70%'}>
                                <Typography sx={{ fontSize: 22, fontFamily: 'Outfit-Regular' }}>Enter your OTP sent to registered email address.</Typography>
                            </Box>

                        </Grid>
                        <Grid item xl={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <OtpInput
                            value={otpValue}
                            onChange={setOtpValue}
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                width: '1rem',
                                height: '1rem',
                                margin: '0 1rem',
                                fontSize: '1rem',
                                border: '1px solid gray',
                                borderRadius: '5px',
                                padding: '0.5rem',
                            }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomButton
                                onClick={SubmitForm}
                                width={'100%'}
                                label={'Confirm'}
                                isIcon={false} />
                                
                        </Grid>
                    </Box>

                </Grid>
            </Grid>
        </Grid>
    );
};

export default Otp;
