// src/components/PaymentSuccess.js

import React from 'react';
import { Typography, Box, Container, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { styled, keyframes } from '@mui/system';



const FullScreenBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly transparent background
    zIndex: 9999, // Ensure it is on top
});

const PaymentSuccess = () => {

    const navigate = useNavigate()

    return (
        <FullScreenBox>
            <Box mt={5} textAlign="center">
                <CheckCircleOutlineIcon style={{ fontSize: 80, color: 'green' }} />
                <Typography variant="h4" mt={2}>
                    Payment Successful!
                </Typography>
                <Typography variant="body1" mt={2}>
                    Thank you for your payment. Your transaction was successful.
                </Typography>
                {/* <Typography variant="body1" mt={2}>
                    Transaction ID: 123456789
                </Typography> */}
                <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={() => navigate('/')}
                >
                    Go to Homepage
                </Button>
            </Box>
        </FullScreenBox>
    );
};

export default PaymentSuccess;
