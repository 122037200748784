import React, { useState } from "react";
import { FormGroup, Typography, MenuItem, Select, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { COLOURS } from "../../assets/COLORS";

const CustomSelect = ({
    fieldName,
    control,
    fieldLabel,
    error,
    children,
    size,
    value,
    onChangeValue,
    readOnly
}) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (!readOnly) {
            setOpen(true);
        }
    };

    return (
        <FormGroup>
            <Typography sx={{
                paddingLeft: '5px',
                fontSize: {
                    lg: size,
                    md: 14,
                    sm: 13,
                    xs: 12,
                },
                fontFamily: 'Outfit-Medium',
            }}>
                {`${fieldLabel}`}
            </Typography>
            <Controller
                name={fieldName}
                control={control}
                render={({ field: { onBlur, onChange } }) => (
                    <Select
                        readOnly={readOnly}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        displayEmpty
                        onChange={(e) => {
                            onChange(e);
                            if (onChangeValue) {
                                onChangeValue(e);
                            }
                        }}
                        onBlur={onBlur}
                        value={value}
                        IconComponent={() => (
                            <Box
                                sx={{
                                    cursor: readOnly ? 'not-allowed' : 'pointer',
                                    opacity: readOnly ? 0.5 : 1,
                                }}
                                marginRight={1}
                                onClick={handleOpen}
                                width={25}
                                height={25}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                color={COLOURS.secondary}
                                borderRadius="15px"
                            >
                                <KeyboardArrowDownSharpIcon style={{ fontSize: 30, fontWeight: 'bold' }} />
                            </Box>
                        )}
                        sx={{
                            border: 'none',
                            borderRadius: "5px",
                            height: 45,
                            background: COLOURS.table,
                        }}
                    >
                        {children}
                    </Select>
                )}
            />
            {error && (
                <p
                    role="alert"
                    style={{
                        color: "red",
                        display: "flex",
                        flexDirection: "start",
                        paddingLeft: "10px",
                        fontSize: "12px",
                    }}
                >
                    {error?.message}
                </p>
            )}
        </FormGroup>
    );
};

export default CustomSelect;
