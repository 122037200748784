// src/components/PaymentFailed.js

import React from 'react';
import { Typography, Box, Container, Button } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PaymentFailed = () => {
    

    return (
        <Container maxWidth="sm">
            <Box mt={5} textAlign="center">
                <ErrorOutlineIcon style={{ fontSize: 80, color: 'red' }} />
                <Typography variant="h4" mt={2}>
                    Payment Failed
                </Typography>
                <Typography variant="body1" mt={2}>
                    Unfortunately, your payment could not be processed.
                </Typography>
                <Typography variant="body1" mt={2}>
                    Please try again or contact our support team for assistance.
                </Typography>
            </Box>
        </Container>
    );
};

export default PaymentFailed;
